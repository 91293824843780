<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Sistemas</h4>
            <div class="small text-muted">Administración de instancias de nuestro propio sistema</div>
          </b-col>
          
          <b-col sm="5">
            <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Sistema">
              <i class="fa fa-plus"></i> Agregar
            </b-button>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">            
        <b-row>            
          <b-col sm="12">
            <b-row>                              
              <b-col sm="3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" size="sm" placeholder="Buscar por nombre o dominio" v-model="filters.name" v-on:keyup.enter.native="filter"></b-form-input>              
                  </b-input-group>
                </b-form-group>                                        
              </b-col>
              <b-col sm="3">
                <v-select :options="arr.select.customers" v-model="filters.customer" placeholder="Cliente" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>              
              <b-col sm="3">
                <v-select :options="arr.select.instances_servers" v-model="filters.instances_server" placeholder="Servidor Instalación" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filter()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>  
            </b-row>     
          </b-col>  
        </b-row>         
      </b-card>

      <b-card header-tag="header" footer-tag="footer">  
        <b-row>
          <b-col>
            <b-table id="instances_system_table" 
                    class="mb-0"
                    ref="table"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"
                    :items="table.items"
                    :fields="table.fields"                    
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :busy="table.isBusy"     
                    v-if="table.items.length || table.isBusy">                       
                
                <template v-slot:cell(id)="data">
                  <b>#{{data.item.id}}</b>
                </template>
                
                <template v-slot:cell(name)="data">
                  <b-icon icon="lock" v-if="data.item.blocked" title="Bloqueado"/>
                  <b>{{data.item.name}}</b>                  
                                    
                  <span v-if="data.item.status_install">
                    <br>
                    <b-icon v-if="!data.item.status_disable" icon="circle-fill" variant="success" title="Habilitado"></b-icon> 
                    <b-icon v-else icon="circle-fill" variant="danger" title="Deshabilitado"></b-icon> 

                    <span v-if="data.item.domain">                    
                      <b-link target="_blank" :href="'http://'+data.item.domain">
                        https://{{data.item.domain}}
                      </b-link>                    
                    </span>
                    <span v-else>
                      <span v-if="data.item.server.subdomain">
                        <b-link target="_blank" :href="'http://'+data.item.name+'.'+data.item.server.subdomain">
                          https://{{data.item.name}}.{{data.item.server.subdomain}}
                        </b-link>
                      </span>
                      <span v-else class="text-danger">                        
                        [Falta configuración de dominio para el sistema]
                      </span>
                    </span>
                  </span>
                  
                  <span v-if="data.item.status_install_site">
                    <br>
                    <b-icon v-if="!data.item.status_disable_site" icon="circle-fill" variant="success" title="Habilitado"></b-icon> 
                    <b-icon v-else icon="circle-fill" variant="danger" title="Deshabilitado"></b-icon> 

                    <span v-if="data.item.domain_site">
                      <b-link target="_blank" :href="'http://'+data.item.domain_site">
                        https://{{data.item.domain_site}}
                      </b-link>                        
                    </span>
                    <span v-else>
                      <span v-if="data.item.server.subdomain">
                        <b-link target="_blank" :href="'http://site.'+data.item.name+'.'+data.item.server.subdomain">
                          https://{{'site.' + data.item.name}}.{{data.item.server.subdomain}}
                        </b-link>                                                  
                      </span>
                      <span v-else class="text-danger">
                        [Falta configuración de dominio para el sitio]
                      </span>                            
                    </span>          
                  </span>
                </template>

                <template v-slot:cell(customer_id)="data">
                  <div v-if="data.item.customer">            
                    <b-avatar :src="data.item.customer.image"
                              v-if="data.item.customer.image">
                    </b-avatar>
                    <b-avatar v-else 
                              icon="building"                                            
                              variant="dark">
                    </b-avatar>                                                     
                    &nbsp;<b>{{data.item.customer.name}}</b>   
                    
                    <b-icon icon="circle-fill" v-if="!data.item.customer.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Cliente INACTIVO" />                                       
                  </div>
                </template>
                
                <template v-slot:cell(observations)="data">
                  <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                    <b-icon icon="chat-square-text"></b-icon>
                  </div>                           
                </template>

                <template v-slot:cell(services)="data">                  
                  <div v-if="data.item.service_customer" v-b-tooltip.hover :title="data.item.service_customer.name" style="cursor:pointer;">
                    <i class="fa fa-rocket"></i>
                  </div>                           
                </template>                

                <template v-slot:cell(instances_server_id)="data">
                  <span v-if="data.item.server">
                    <b>{{data.item.server.name}}</b><br>
                    <span v-if="data.item.server.subdomain">
                      <b-link target="_blank" :href="'http://'+data.item.server.subdomain">
                        https://{{data.item.server.subdomain}}
                      </b-link>  
                    </span>
                  </span>
                </template>              

                <template v-slot:cell(status_system)="data">                  
                  <div v-if="data.item.status_install" :id="'server-system-' + data.item.id">
                    <div class="text-success font-weight-bold">
                      <b-icon icon="circle-fill" variant="success"></b-icon> Instalado
                    </div>
                  </div>                    
                  <div v-else :id="'server-system-' + data.item.id">
                    <div class="text-danger font-weight-bold">
                      <b-icon icon="circle-fill" variant="danger"></b-icon> No Instalado
                    </div>
                  </div>

                  <div v-if="data.item.pwa_status">
                    <div class="text-success font-weight-bold">
                      <b-icon icon="circle-fill" variant="success"></b-icon> PWA Instalado
                    </div>
                  </div>                    
                  <div v-else>
                    <div class="text-danger font-weight-bold">
                      <b-icon icon="circle-fill" variant="danger"></b-icon> PWA No Instalado
                    </div>
                  </div>

                  <b-popover :target="'server-system-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="data.item.status_install && data.item.system">

                    <template #title>Estado del Sistema</template>                    
                    <b>Versión:</b> {{JSON.parse(data.item.system).version}}<br>
                    <b>Contraseña:</b> {{JSON.parse(data.item.system).pass}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.system).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                         
                </template>                

                <template v-slot:cell(status_site)="data">                  
                  <div v-if="data.item.status_install_site">
                    <div class="text-success font-weight-bold" :id="'server-site-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon> Instalado
                    </div>
                  </div>                    
                  <div v-else :id="'server-site-' + data.item.id">
                    <div class="text-danger font-weight-bold">
                      <b-icon icon="circle-fill" variant="danger"></b-icon> No Instalado
                    </div>
                  </div>

                  <div v-if="data.item.pwa_status_site">
                    <div class="text-success font-weight-bold">
                      <b-icon icon="circle-fill" variant="success"></b-icon> PWA Instalado
                    </div>
                  </div>                    
                  <div v-else>
                    <div class="text-danger font-weight-bold">
                      <b-icon icon="circle-fill" variant="danger"></b-icon> PWA No Instalado
                    </div>
                  </div>

                  <b-popover :target="'server-site-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="data.item.status_install && data.item.status_install_site && data.item.site">

                    <template #title>Estado del Sitio Web</template>                    
                    <b>Versión:</b> {{JSON.parse(data.item.site).version}}<br>                    
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.site).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                    
                </template>                

                <template v-slot:cell(status_process)="data">
                  <div v-if="data.item.status_process">
                    <div class="text-info font-weight-bold">
                      <b-icon icon="circle-fill" variant="info" animation="fade"></b-icon> Procesando
                    </div>
                  </div>
                </template>

                <template v-slot:cell(instances_server_backups_id)="data">
                  <span v-if="data.item.server_backups">
                    <b>{{data.item.server_backups.name}}</b>                    
                  </span>
                </template>

                <template v-slot:cell(backups)="data">
                  <div v-if="data.item.status_install && data.item.backups">
                    <div v-if="showFieldSystem(data.item, 'backups') == 'Activo'" class="text-success font-weight-bold" :id="'server-backups-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldSystem(data.item, 'backups') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-backups-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>   
                  </div>

                  <b-popover :target="'server-backups-' + data.item.id" 
                              triggers="hover" 
                              placement="top" 
                              v-if="data.item.status_install && data.item.backups">

                    <template #title>Estado del Backups</template>                    
                    <b>Estado:</b> {{JSON.parse(data.item.backups).status}}<br>
                    <b>Últimos Backups:</b> {{JSON.parse(data.item.backups).last}}<br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.backups).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>                   
                </template>

                <template v-slot:cell(audit)="data">
                  <div v-if="data.item.status_install && data.item.audit">
                    <div v-if="showFieldSystem(data.item, 'audit') == 'Activo'" class="text-success font-weight-bold" :id="'server-audit-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldSystem(data.item, 'audit') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-audit-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>   
                  </div>

                  <b-popover :target="'server-audit-' + data.item.id" 
                              triggers="hover" 
                              placement="top"
                              @show="configViewerJson" 
                              v-if="data.item.status_install && data.item.audit">

                    <template #title>Auditoría</template>                    
                    <b>Estado:</b> {{JSON.parse(data.item.audit).status}}<br>
                    <b>Auditoría:</b> <json-tree v-if="JSON.parse(data.item.audit).audit" :data="JSON.parse(JSON.parse(data.item.audit).audit)" :level="1" class="intances-audit-pre-json"></json-tree><br>
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.audit).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>   
                </template>

                <template v-slot:cell(access)="data">
                  <div v-if="data.item.status_install && data.item.access">
                    <div v-if="showFieldSystem(data.item, 'access') == 'Activo'" class="text-success font-weight-bold" :id="'server-access-' + data.item.id">
                      <b-icon icon="circle-fill" variant="success"></b-icon>
                    </div>
                    <div v-if="showFieldSystem(data.item, 'access') == 'Inactivo'" class="text-danger font-weight-bold" :id="'server-access-' + data.item.id">
                      <b-icon icon="circle-fill" variant="danger"></b-icon>
                    </div>   
                  </div>

                  <b-popover :target="'server-access-' + data.item.id" 
                              triggers="hover" 
                              placement="top"
                              @show="configViewerJson" 
                              v-if="data.item.status_install && data.item.access">

                    <template #title>Accesos URL</template>                    
                    <b>Estado:</b> {{JSON.parse(data.item.access).status}}<br>
                    <div v-if="JSON.parse(data.item.access).domain">
                      <b>Sistema:</b> {{JSON.parse(data.item.access).domain}}<br>
                    </div>
                    <div v-if="JSON.parse(data.item.access).domain_alt">
                      <b>Sistema Alternativo:</b> {{JSON.parse(data.item.access).domain_alt}}<br>
                    </div>                    
                    <div v-if="JSON.parse(data.item.access).domain_site">
                      <b>Sitio Web:</b> {{JSON.parse(data.item.access).domain_site}}<br>
                    </div>
                    <div v-if="JSON.parse(data.item.access).domain_site_alt">
                      <b>Sitio Web Alternativo:</b> {{JSON.parse(data.item.access).domain_site_alt}}<br>
                    </div>                    
                    <b>Última Sincronización:</b> {{moment(JSON.parse(data.item.access).datetime).format('DD/MM/YYYY HH:mm:ss')}}
                  </b-popover>   
                </template>

                <template v-slot:cell(dns)="data">
                  <div v-if="data.item.status_install && data.item.domain && data.item.access">
                    <b-icon v-if="JSON.parse(data.item.access).dns_type_aaaa" icon="info-circle-fill" class="mr-1" variant="info" title="Encontramos registros tipo AAAA en los DNS"></b-icon>
                    <b-icon v-if="JSON.parse(data.item.access).dns_get_ip && (JSON.parse(data.item.access).dns_get_ip != data.item.server.host)" icon="folder-symlink-fill" variant="info" title="La IP asociada al registro tipo A no coincide con la IP del servidor"></b-icon>
                  </div>
                </template>

                <template v-slot:cell(f_action)="data">
                  <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                    
                    <b-dropdown-item @click="blockedSystem(data.item)" v-if="!data.item.blocked">
                      <b-icon icon="lock-fill"></b-icon> Bloquear
                    </b-dropdown-item>         
                    <b-dropdown-item @click="unlockedSystem(data.item)" v-else>
                      <b-icon icon="lock-fill"></b-icon> Desbloquear
                    </b-dropdown-item>         

                    <b-dropdown-header>Consola</b-dropdown-header>                    
                    <b-dropdown-item @click="actionSystem(data.item)">
                      <b-icon icon="terminal-fill"></b-icon> Comandos
                    </b-dropdown-item>         
                    
                    <b-dropdown-header>Acciones</b-dropdown-header>
                    <b-dropdown-item @click="edit(data.item)">
                      <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                    </b-dropdown-item>
                    <b-dropdown-item @click="remove(data.item)">
                      <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>

          <b-col>            
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                            size="sm"
                            pills=""
                            :total-rows="table.tablePaginate.total"
                            :per-page="table.tablePaginate.per_page"
                            v-model="table.currentPage" 
                            @input="filter()" />
            </nav>             
          </b-col>          
        </b-row>
      </b-card>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Nombre">
              <b-form-input type="text"
                            size="sm"
                            v-model="crud.form.name"
                            required
                            placeholder="Ingresar un nombre"
                            :disabled="crud.form.id>0">
              </b-form-input>
            </b-form-group>
          </b-col>           
          <b-col md="12">
            <FindObject render="search"
                        type="customers" 
                        @select-object="loadCustomers($event)" 
                        :valueID="crud.form.customer_id"
                        :where="conditionCustomersActive" />           
          </b-col>
          <b-col md="12">
            <FindObject tag="VPS Instalación"  
                        render="search"
                        type="instances-server" 
                        @select-object="loadInstancesServer($event)" 
                        :valueID="crud.form.instances_server_id" 
                        :where="conditionServerType" />           
          </b-col>       
          <b-col md="12">
            <FindObject tag="VPS Backups"
                        render="search"
                        type="instances-server" 
                        @select-object="loadInstancesServerBackups($event)" 
                        :valueID="crud.form.instances_server_backups_id" 
                        :where="conditionServerBackupsType" />           
          </b-col>    

          <b-col md="12" v-if="parameters.integrateServices">
            <FindObject tag="Servicio"              
                        render="search"
                        display="name"
                        type="servicesCustomers" 
                        @select-object="loadServicesCustomersDetails($event)" 
                        :valueID="crud.form.services_customers_details_id" 
                        :where="conditionServicesCustomersDetails" 
                        :key="crud.form.customer_id"/>           
          </b-col>              

          <b-col md="12">
            <b-form-group label="Observaciones">
              <b-form-textarea                
                v-model="crud.form.observations"
                placeholder="Ingresar las observaciones..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-col>       

          <b-col md="12" v-if="!crud.form.id">
            <hr>
          </b-col>    
          <b-col md="6" v-if="!crud.form.id">
            <b-form-checkbox v-model="crud.form.status_system" 
                              switch 
                              size="sm" 
                              class="pull-left">
              ¿Sistema ya instalado?
            </b-form-checkbox> 
          </b-col>  
          <b-col md="6" v-if="!crud.form.id">
            <b-form-checkbox v-model="crud.form.status_site" 
                              switch 
                              size="sm" 
                              class="pull-left">
              ¿Sitio Web ya instalado?
            </b-form-checkbox> 
          </b-col> 
          <b-col md="12">
            <hr>
          </b-col>   
          <b-col md="12" class="mb-4">
            <b-form-checkbox v-model="crud.form.autocontrol" 
                              switch 
                              size="sm" 
                              class="pull-left">
              ¿Es el sistema principal? <small>Le llamamos sistema principal al padre que controla todos los sistemas hijos</small>
            </b-form-checkbox> 
          </b-col>                                              
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
      </b-modal>

      <!-- CRUD COMMAND -->
      <b-modal v-model="modal.command.active"
              size="lg"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.command.title}}
        </div>

        <b-row v-if="selectedSystem">
          <b-col md="2">                          
            <b-row>
              <b-col md="12">                          
                <b-dropdown block split text="Sistema" class="mb-2 w-100">
                  <b-dropdown-item @click="getActionSystem('system-get-password','Obtener Contraseña (superadmin/admin)')" v-if="selectedSystem.status_install">Obtener Contraseña (superadmin/admin)</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-get-version','Obtener Versión')" v-if="selectedSystem.status_install">Obtener Versión</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-get-last-log-error','Obtener Últimos Errores')" v-if="selectedSystem.status_install">Obtener Últimos Errores</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-clean-last-log-error','Limpiar Últimos Errores')" v-if="selectedSystem.status_install">Limpiar Últimos Errores</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-audit','Auditoria del sistema')" v-if="selectedSystem.status_install">Auditar</b-dropdown-item>
                  <b-dropdown-item @click="getActionSystem('system-check-access','Verificar Acceso')" v-if="selectedSystem.status_install">Verificar Acceso</b-dropdown-item>

                  <b-dropdown-divider v-if="selectedSystem.status_install"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-pwa-disable','Deshabilitar PWA')" v-if="selectedSystem.status_install && selectedSystem.pwa_status">Deshabilitar PWA</b-dropdown-item>
                  <b-dropdown-item @click="getActionSystem('system-pwa-enable','Habilitar PWA')" v-if="selectedSystem.status_install && !selectedSystem.pwa_status">Habilitar PWA</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install && !selectedSystem.status_disable"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-domain','Apuntar Dominio')" v-if="selectedSystem.status_install && !selectedSystem.status_disable">Apuntar Dominio</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-ssl-generate','Generar SSL')" v-if="selectedSystem.status_install && !selectedSystem.status_disable && selectedSystem.domain">Generar SSL</b-dropdown-item>                  
                  
                  <b-dropdown-divider v-if="selectedSystem.status_install"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-disable','Deshabilitar Sistema')" v-if="!selectedSystem.status_disable && selectedSystem.status_install">Deshabilitar</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-enable','Habilitar Sistema')" v-if="selectedSystem.status_disable && selectedSystem.status_install">Habilitar</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-install','Instalar Sistema')" v-if="!selectedSystem.status_install && !selectedSystem.blocked">Instalar</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-update','Actualizar Sistema')" v-if="selectedSystem.status_install">Actualizar</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-update-force','Actualizar Sistema Forzada')" v-if="selectedSystem.status_install">Actualizar (Forzar)</b-dropdown-item>                  
                  <b-dropdown-divider v-if="selectedSystem.status_install && !selectedSystem.status_install_site && !selectedSystem.blocked"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-uninstall','Desinstalar Sistema')" v-if="selectedSystem.status_install && !selectedSystem.status_install_site && !selectedSystem.blocked">Desinstalar</b-dropdown-item>           
                </b-dropdown>

                <b-dropdown block split text="Sitio Web" class="mb-2 w-100" v-if="selectedSystem.status_install">
                  <b-dropdown-item @click="getActionSystem('system-web-get-version','Obtener Versión')" v-if="selectedSystem.status_install_site">Obtener Versión</b-dropdown-item>                  
                  
                  <b-dropdown-divider v-if="selectedSystem.status_install_site"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-web-get-log-error','Obtener Errores')" v-if="selectedSystem.status_install_site">Obtener Errores</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-web-clean-log-error','Limpiar Errores')" v-if="selectedSystem.status_install_site">Limpiar Errores</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install && selectedSystem.status_install_site"></b-dropdown-divider>
                  <b-dropdown-item @click="getActionSystem('system-web-pwa-disable','Deshabilitar PWA')" v-if="selectedSystem.status_install && selectedSystem.status_install_site && selectedSystem.pwa_status_site">Deshabilitar PWA</b-dropdown-item>
                  <b-dropdown-item @click="getActionSystem('system-web-pwa-enable','Habilitar PWA')" v-if="selectedSystem.status_install && selectedSystem.status_install_site && !selectedSystem.pwa_status_site">Habilitar PWA</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install && selectedSystem.status_install_site && !selectedSystem.status_disable_site"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-web-domain','Apuntar Dominio')" v-if="selectedSystem.status_install && selectedSystem.status_install_site && !selectedSystem.status_disable_site">Apuntar Dominio</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-web-ssl-generate','Generar SSL')" v-if="selectedSystem.status_install && selectedSystem.status_install_site && !selectedSystem.status_disable_site  && selectedSystem.domain_site">Generar SSL</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install_site"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-web-disable','Deshabilitar Sitio Web')" v-if="!selectedSystem.status_disable_site && selectedSystem.status_install_site">Deshabilitar</b-dropdown-item>                  
                  <b-dropdown-item @click="getActionSystem('system-web-enable','Habilitar Sitio Web')" v-if="selectedSystem.status_disable_site && selectedSystem.status_install_site">Habilitar</b-dropdown-item>                  

                  <b-dropdown-divider v-if="selectedSystem.status_install_site"></b-dropdown-divider>                                                 
                  <b-dropdown-item @click="getActionSystem('system-web-install','Instalar Sitio Web')" v-if="!selectedSystem.status_install_site && !selectedSystem.blocked">Instalar</b-dropdown-item>                 
                  <b-dropdown-item @click="getActionSystem('system-web-update','Actualizar Sitio Web')" v-if="selectedSystem.status_install_site">Actualizar</b-dropdown-item>     
                  <b-dropdown-divider v-if="selectedSystem.status_install_site && !selectedSystem.blocked"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-web-uninstall','Desinstalar Sitio Web')" v-if="selectedSystem.status_install_site && !selectedSystem.blocked">Desinstalar</b-dropdown-item>           
                </b-dropdown>                
              </b-col>
                          
              <b-col md="12" v-if="selectedSystem.status_install && !selectedSystem.blocked">
                <hr class="instance-system-divide">
              </b-col>

              <b-col md="12">  
                <b-dropdown block split text="Productos" class="mb-2 w-100" v-if="selectedSystem.status_install && !selectedSystem.blocked">
                  <!-- <b-dropdown-item @click="getActionSystem('system-install-products-website','Sitios Web / Landing Page')" v-if="selectedSystem.status_install && selectedSystem.status_install_site">Sitio Web / Landing Page</b-dropdown-item> -->
                  <b-dropdown-item @click="getActionSystem('system-install-products-ecommerce','Ecommerce [B2B, B2C, C2C]')" v-if="selectedSystem.status_install && selectedSystem.status_install_site">Ecommerce [B2B, B2C, C2C]</b-dropdown-item>
                  <b-dropdown-divider v-if="selectedSystem.status_install && selectedSystem.status_install_site"></b-dropdown-divider>
                  <!-- <b-dropdown-item @click="getActionSystem('system-install-products-whatsapp-marketing','Whatsapp Marketing')" v-if="selectedSystem.status_install">Whatsapp Marketing</b-dropdown-item> -->
                  <!-- <b-dropdown-item @click="getActionSystem('system-install-products-crm','CRM')" v-if="selectedSystem.status_install">CRM</b-dropdown-item> -->
                  <!-- <b-dropdown-item @click="getActionSystem('system-install-products-projects','Proyectos')" v-if="selectedSystem.status_install">Proyectos</b-dropdown-item> -->                  
                  <!-- <b-dropdown-item @click="getActionSystem('system-install-products-gestion-simple','Gestión Simple')" v-if="selectedSystem.status_install">Gestión Simple</b-dropdown-item> --> 
                  <b-dropdown-item @click="getActionSystem('system-install-products-gestion-full','Gestión Completa')" v-if="selectedSystem.status_install">Gestión Completa</b-dropdown-item>                  
                </b-dropdown>
              </b-col>

              <b-col md="12" v-if="selectedSystem.status_install">
                <hr class="instance-system-divide">
              </b-col>
              
              <b-col md="12">  
                <b-dropdown block split text="Backups" class="mb-2 w-100" v-if="selectedSystem.status_install">                  
                  <b-dropdown-item @click="getActionSystem('system-backups-get-now','Backups Hoy')" v-if="selectedSystem.status_install && selectedSystem.backups">Backups Hoy</b-dropdown-item>             
                  <b-dropdown-divider v-if="selectedSystem.status_install && selectedSystem.backups"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-backups-list-db','Listar Bases de datos')" v-if="selectedSystem.status_install && selectedSystem.backups">Listar Base de Datos</b-dropdown-item>
                  <b-dropdown-item @click="getActionSystem('system-backups-list-img','Listar Imágenes')" v-if="selectedSystem.status_install && selectedSystem.backups">Listar Imágenes</b-dropdown-item>     
                  <b-dropdown-divider v-if="selectedSystem.status_install && selectedSystem.backups"></b-dropdown-divider>                               
                  <b-dropdown-item @click="getActionSystem('system-backups-force-files','Forzar Backups: FILES')" v-if="selectedSystem.status_install && selectedSystem.backups">Forzar: FILES</b-dropdown-item>                                
                  <b-dropdown-item @click="getActionSystem('system-backups-force-db','Forzar Backups: DB')" v-if="selectedSystem.status_install && selectedSystem.backups">Forzar: DB</b-dropdown-item>                                
                  <b-dropdown-divider v-if="selectedSystem.status_install && selectedSystem.backups"></b-dropdown-divider>                                                 
                  <b-dropdown-item @click="getActionSystem('system-backups-configurate','Configurar Backups')" v-if="selectedSystem.status_install && !showConfigurateBackupsActive()">Configurar</b-dropdown-item>                                        
                  <b-dropdown-item @click="getActionSystem('system-backups-delete','Quitar Backups')" v-if="selectedSystem.status_install && showConfigurateBackupsActive()">Quitar</b-dropdown-item>                                        
                </b-dropdown>
              </b-col>              

            </b-row>
          </b-col>
          <b-col md="10">
            <b-row>
              <b-col md="8">
                <h4>{{this.modal.command.subtitle}}</h4>
              </b-col>
              <b-col md="4" class="ssh-console-footer">
                <span v-if="selectedSystem.status_process">
                  <span class="text-info font-weight-bold">
                    <b-icon icon="circle-fill" variant="info" animation="fade"></b-icon> Procesando
                  </span>
                </span>                
                <b-button variant="danger" size="sm" class="pull-right" title="Refrescar" @click="refreshSystem()" v-if="!SSHLoader">
                  <b-icon icon="arrow-clockwise" />
                </b-button>
              </b-col>
            </b-row>
            <div v-if="!SSHLoader" class="ssh-console-system" v-html="SSHConsole"></div>
            <div v-else class="text-left mt-5">
              <b-spinner label="Spinning" type="grow"></b-spinner>
            </div>
          </b-col>
        </b-row>

        <div slot="modal-footer" class="ssh-console-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="closeActionSystem()">Cerrar</b-button>                  
        </div>
      </b-modal>     

      <!-- CRUD DOMAIN SYSTEM -->
      <b-modal v-model="modal.formSystem.active"
              size="xl"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSystem.title}}
        </div>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Domino">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSystem.domain"
                                required
                                placeholder="Ingresa un dominio">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-alert varian="info" show>
                  Puede demorar unas horas en quedar activo. Dependerá de la propagación de los DNS de su proveedor de dominios.
                </b-alert>
              </b-col>
            </b-row>
          </b-col>                                       
          <b-col md="6" v-if="this.selectedSystem">
            <b-card bg-variant="light" header="Implementación" text-variant="dark">
              <b-card-text>
                <p>
                  Para apuntar su dominio personalizado al sistema, siga los siguientes pasos:
                </p>            
                
                <hr>                

                <ul>
                  <li>Ingresar a la <b>zona DNS</b> de su dominio.</li>                  
                  <li>Buscar el registro <b>(Tipo A => su-dominio.com => [IP])</b></li>
                  <li>Editar la IP actual por la IP de nuestro servidor. <b>IP = {{this.selectedSystem.server.host}}</b> </li>
                  <li>El siguiente registro debe mantener esta estructura <b>(CNAME => www.su-dominio.com => su-dominio.com)</b></li>
                </ul>              
              </b-card-text>
            </b-card>
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSystem.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveDomain('system')">Apuntar</b-button>          
        </div>
      </b-modal> 

      <!-- CRUD DOMAIN SITE -->
      <b-modal v-model="modal.formSite.active"
              size="xl"
              header-bg-variant="dark"
              header-text-variant="white">
        <div slot="modal-header">
          {{this.modal.formSite.title}}
        </div>

        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="12">
                <b-form-group label="Domino">
                  <b-form-input type="text"
                                size="sm"
                                v-model="crud.formSite.domain"
                                required
                                placeholder="Ingresa un dominio">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-alert varian="info" show>
                  Puede demorar unas horas en quedar activo. Dependerá de la propagación de los DNS de su proveedor de dominios.
                </b-alert>
              </b-col>
            </b-row>
          </b-col>                                       
          <b-col md="6" v-if="this.selectedSystem">
            <b-card bg-variant="light" header="Implementación" text-variant="dark">
              <b-card-text>
                <p>
                  Para apuntar su dominio personalizado al sistema, siga los siguientes pasos:
                </p>            
                
                <hr>                

                <ul>
                  <li>Ingresar a la <b>zona DNS</b> de su dominio.</li>                  
                  <li>Buscar el registro <b>(Tipo A => su-dominio.com => [IP])</b></li>
                  <li>Editar la IP actual por la IP de nuestro servidor. <b>IP = {{this.selectedSystem.server.host}}</b> </li>
                  <li>El siguiente registro debe mantener esta estructura <b>(CNAME => www.su-dominio.com => su-dominio.com)</b></li>
                </ul>              
              </b-card-text>
            </b-card>
          </b-col>          
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.formSite.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="saveDomain('site')">Apuntar</b-button>          
        </div>
      </b-modal>             
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'  
  import Storage from '@/handler/storageLocal'

  import Vue from 'vue'
  import JsonTree from 'vue-json-tree'
  Vue.component('json-tree', JsonTree)

  export default {
    components: {
      FindObject,      
    },        
    data: () => {
      return {      
        access: {
          module_id: Modules.INSTANCIAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'crudInstancesSystem',
          elements: {}
        },       
        parameters: {
          integrateServices: Helper.hasParametersAccess(Param.P59),          
        },              
        table : {
          items: [],
          fields: [],          
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,                
        },
        crud: {
          form: {
            id: 0,
            name: '',  
            customer_id: 0,
            customer: null,
            instances_server_id: 0,
            instances_server: null,   
            instances_server_backups_id: 0,
            instances_server_backups: null,                 
            services_customers_details_id: 0,
            services_customers_details: null,
            observations: '',
            own: true,
            status_system: false,
            status_site: false,
            autocontrol: false,
          },     
          formSystem: {
            id: 0,
            domain: '',
          },     
          formSite: {
            id: 0,
            domain: '',
          },               
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
          command: {
            active: false,
            title: '',
            subtitle: 'Terminal',
          },       
          formSystem: {
            active: false,
            title: ''            
          },   
          formSite: {
            active: false,
            title: ''            
          }             
        },
        filters: {
          name: '',
          customer: 0,
          instances_server: 0,
          own: true,
        },
        arr: {
          select: {
            customers: [],
            instances_servers: [],
          }
        },       
        selectedServer: null,           
        selectedSystem: null,
        SSHConsole: '',
        SSHLoader: false,    
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoadSelects()
      this.restoreFilterStorage() 

      this.loadFieldTable()

      this.filter()

      this.configViewerJson()
    },
    computed: {      
      // CONDITIONS SELECT
      conditionCustomersActive(){
        return [{field: 'active', condition: true}];
      },  
      conditionServerType(){
        return [{field: 'type', condition: 'fullcontrol'}];
      },  
      conditionServerBackupsType(){
        return [{field: 'type', condition: 'backups'}];
      },         
      conditionServicesCustomersDetails() {
        return [
          {field: 'customer_id', condition: this.crud.form.customer_id},
          {field: 'type_instances', condition: 'system'},          
        ];
      }
    },    
    methods: {
      // config
      configViewerJson() {    
        setTimeout(()=>{
          var arrElement = document.getElementsByClassName("json-tree-indent");        
          if(arrElement) {
            for (var i = 0; i < arrElement.length; i++){
              if(arrElement[i].innerHTML != '&nbsp;') {
                arrElement[i].innerHTML = '&nbsp;';            
              }                        
            }                              
          }
        },100)                    
      },  
      loadFieldTable() {       
        this.table.fields.push({key: 'id', label: 'ID', sortable: true, class: 'align-middle'})
        
        if(this.parameters.integrateServices) {
          this.table.fields.push({key: 'services', label: '', class: 'align-middle'})                 
        }
        
        this.table.fields.push({key: 'name', label: 'Nombre', class: 'align-middle'})
        this.table.fields.push({key: 'customer_id', label: 'Cliente', class: 'align-middle'})
        this.table.fields.push({key: 'observations', label: '', class: 'align-middle'})
        this.table.fields.push({key: 'instances_server_id', label: 'VPS Instalación', class: 'align-middle'})
        this.table.fields.push({key: 'status_system', label: 'Sistema', class: 'align-middle text-center'})
        this.table.fields.push({key: 'status_site', label: 'Sitio Web', class: 'align-middle text-center'})
        this.table.fields.push({key: 'status_process', label: '', class: 'align-middle text-center'})
        this.table.fields.push({key: 'instances_server_backups_id', label: 'VPS Backups', class: 'align-middle'})
        this.table.fields.push({key: 'backups', label: 'Backups', class: 'align-middle text-center'})
        this.table.fields.push({key: 'audit', label: 'Auditoria', class: 'align-middle text-center'})
        this.table.fields.push({key: 'access', label: 'Accesos', class: 'align-middle text-center'})
        this.table.fields.push({key: 'dns', label: 'DNS', class: 'align-middle text-center'})
        this.table.fields.push({key: 'f_action', label:'', class: 'align-middle text-center'})
      },
      // crud
      filter() {
        this.saveFilterStorage()

        this.table.isBusy = true

        var result = serviceAPI.filtrarSistema(this.filters, this.table.currentPage)

        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data

          this.table.items.forEach(element => {            
            if(element.autocontrol) {
              element._rowVariant = 'dark'
            }            
          });

          if(this.$refs.table) {                        
            this.$refs.table.$forceUpdate()
          }  

          this.table.isBusy = false          
        })
        .catch(error => {
          this.table.isBusy = false
          //this.$awn.alert(Error.showError(error))
        });        
      },
      add() {
        this.crud.form.id = 0
        this.crud.form.name = '' 
        this.crud.form.customer_id = 0
        this.crud.form.customer = null
        this.crud.form.instances_server_id = 0
        this.crud.form.instances_server = null   
        this.crud.form.instances_server_backups_id = 0
        this.crud.form.instances_server_backups = null        
        this.crud.form.services_customers_details_id = 0
        this.crud.form.services_customers_details = null
        this.crud.form.observations = ''
        this.crud.form.status_system = false
        this.crud.form.status_site = false
        this.crud.form.autocontrol = false

        this.modal.form.title = "Nuevo Sistema"
        this.modal.form.active = true
      },
      edit(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name     
        this.crud.form.customer_id = item.customer.id
        this.crud.form.customer = item.customer
        this.crud.form.instances_server_id = item.instances_server_id
        this.crud.form.instances_server = item.server
        this.crud.form.instances_server_backups_id = item.instances_server_backups_id
        this.crud.form.instances_server_backups = item.server_backups
        this.crud.form.services_customers_details_id = item.services_customers_details_id
        this.crud.form.services_customers_details = item.services_customers_details
        this.crud.form.observations = item.observations
        this.crud.form.autocontrol = item.autocontrol
        
        this.modal.form.title = "Editar Sistema"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el sistema (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Sistema',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarSistema(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filter()
              this.$awn.success("Sistema eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarSistema(this.crud.form);
        } else {
          var result = serviceAPI.agregarSistema(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.filter()
          this.$awn.success("Cambios guardado con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      }, 

      // render
      showConfigurateBackupsActive() {
        var status = true

        if(!this.selectedSystem.backups) {
          status = false
        } else {
          if(JSON.parse(this.selectedSystem.backups).status=='Inactivo') {
            status = false
          }
        }
        
        return status
      },                 
      blockedSystem(item) {
        this.$bvModal.msgBoxConfirm('¿Desea bloquear el sistema (' + item.name + ')?', {
          title: 'Bloquear sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();

            var result = serviceAPI.commandBlockedSistema({
              id: item.id,
              blocked: true,
            });
          
            result.then((response) => {
              loader.hide()
              this.filter()
              this.$awn.success("Bloqueo con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      },
      unlockedSystem(item) {
        this.$bvModal.msgBoxConfirm('¿Desea desbloquear el sistema (' + item.name + ')?', {
          title: 'Desbloquear sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'secondary',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'secondary',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            let loader = this.$loading.show();

            var result = serviceAPI.commandBlockedSistema({
              id: item.id,
              blocked: false,
            });
          
            result.then((response) => {
              loader.hide()
              this.filter()
              this.$awn.success("Desbloqueo con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })            
      },

      // SELECT
      loadCustomers(object) {        
        if(object){
          this.crud.form.customer = object
          this.crud.form.customer_id = object.id                 
        } else {
          this.crud.form.customer = null
          this.crud.form.customer_id = 0
        }
      },
      loadInstancesServer(object) {
        if(object){
          this.crud.form.instances_server = object
          this.crud.form.instances_server_id = object.id                 
        } else {
          this.crud.form.instances_server = null
          this.crud.form.instances_server_id = 0
        }        
      },
      loadInstancesServerBackups(object) {
        if(object){
          this.crud.form.instances_server_backups = object
          this.crud.form.instances_server_backups_id = object.id                 
        } else {
          this.crud.form.instances_server_backups = null
          this.crud.form.instances_server_backups_id = 0
        }        
      },  
      loadServicesCustomersDetails(object) {
        if(object){
          this.crud.form.services_customers_details = object
          this.crud.form.services_customers_details_id = object.id                 
        } else {
          this.crud.form.services_customers_details = null
          this.crud.form.services_customers_details_id = 0
        }  
      },

      // fields server table 
      showFieldSystem(item, type) {
        var data = null
        var obj = null
       
        if(type == 'backups') {          
          obj = JSON.parse(item.backups)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        if(type == 'audit') {          
          obj = JSON.parse(item.audit)

          if(obj && obj.status) {
            data = obj.status
          }
        }

        if(type == 'access') {          
          obj = JSON.parse(item.access)

          if(obj && obj.status) {
            data = obj.status
          }
        }        

        return data
      },

      // filter select
      filterLoadSelects() {
        this.filterLoadCustomers();  
        this.filterLoadServers();
      },
      filterLoadCustomers(){
        var result = serviceAPI.obtenerClientes()        
        result.then((response) => {
          var data = response.data    

          let arrActive = []
          let arrInactive = []          
          data.forEach(element => {            
            if(element.active)
              arrActive.push({ code: element.id, label: element.name })            
            else 
              arrInactive.push({ code: element.id, label: '[INACTIVO] - ' + element.name })                                    
          });      
          this.arr.select.customers = arrActive.concat(arrInactive);           
        })   
      },
      filterLoadServers() {
        var result = serviceAPI.obtenerServidor()        
        result.then((response) => {
          var data = response.data    

          data.forEach(element => {        
            if(element.type=='fullcontrol') {
              this.arr.select.instances_servers.push({code: element.id, label: element.name})
            }            
          });                                
        })   
      },

      // action
      actionSystem(item) {
        this.selectedServer = item.server
        this.selectedSystem = item

        this.SSHConsole = ''

        this.modal.command.title = "Comandos Sistema: [" + item.name + "]"
        this.modal.command.subtitle = "Terminal"

        this.modal.command.active = true
      },
      closeActionSystem() {
        this.modal.command.active=false
        this.filter()
      },
      refreshSystem() {                
        var result = serviceAPI.mostrarSistema(this.selectedSystem.id);
        
        result.then((response) => {
          var data = response.data

          this.selectedSystem = data

          this.$awn.success("Sistema refrescado");
        })        
      },
 
      getActionSystem(reference, titulo) {
        // titulo de referencia
        this.modal.command.subtitle = titulo

        // si apunta un dominio para el sistema, lo maneja otro evento
        if(reference == 'system-domain') {
          this.domainSystem(reference)
          return false
        }

        // si disable el sistema, lo maneja otro evento
        if(reference == 'system-disable') {
          this.disableSystem(reference)
          return false
        }

        // si enable el sistema, lo maneja otro evento
        if(reference == 'system-enable') {
          this.enableSystem(reference)
          return false
        }

        // si instala el sistema, lo maneja otro evento
        if(reference == 'system-install') {
          this.installSystem(reference)
          return false
        }

        // si actualiza el sistema, lo maneja otro evento
        if(reference == 'system-update') {
          this.updateSystem(reference, false)
          return false
        }

        // si actualiza el sistema, lo maneja otro evento
        if(reference == 'system-update-force') {
          this.updateSystem(reference, true)
          return false
        }

        // si desinstala el sistema, lo maneja otro evento
        if(reference == 'system-uninstall') {
          this.uninstallSystem(reference)
          return false
        }

        // si apunta un dominio para el sitio web, lo maneja otro evento
        if(reference == 'system-web-domain') {
          this.domainSite(reference)
          return false
        }

        // si disable el sitio web, lo maneja otro evento
        if(reference == 'system-web-disable') {
          this.disableWebSystem(reference)
          return false
        }

        // si enable el sitio web, lo maneja otro evento
        if(reference == 'system-web-enable') {
          this.enableWebSystem(reference)
          return false
        }

        // si instala el sitio web, lo maneja otro evento
        if(reference == 'system-web-install') {
          this.installWebSystem(reference)
          return false
        }

        // si actualiza el sitio web, lo maneja otro evento
        if(reference == 'system-web-update') {
          this.updateWebSystem(reference, false)
          return false
        }

        // si desinstala el sitio web, lo maneja otro evento
        if(reference == 'system-web-uninstall') {
          this.uninstallWebSystem(reference)
          return false
        }

        // si instala un producto, lo maneja otro evento
        if(reference.includes('system-install-products')) {
          this.installProductsSystem(reference, titulo)
          return false          
        }

        // si configura el backups, lo maneja otro evento
        if(reference == 'system-backups-configurate') {
          this.backupsConfigurateSystem(reference)
          return false
        }

        // si borro el backups, lo maneja otro evento
        if(reference == 'system-backups-delete') {
          this.backupsDeleteSystem(reference)
          return false
        }        

        // si borro el log del sistema, lo maneja otro evento
        if(reference == 'system-clean-last-log-error') {
          this.cleanLogErrorSystem(reference)
          return false
        }        

        // si borro el log del sitio web, lo maneja otro evento
        if(reference == 'system-web-clean-log-error') {
          this.cleanLogErrorSite(reference)
          return false
        }        

        // comandos
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 

        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            

        this.SSHLoader = true
        var result = serviceAPI.commandActionSistema({
          id: this.selectedServer.id, 
          command: reference,
          reference: this.selectedSystem.name,
          domain: this.selectedServer.subdomain,
          system_id: this.selectedSystem.id,
        });

        result.then((response) => {                    
          var data = response.data

          this.SSHConsole = data
          this.filter()
          this.SSHLoader = false
        })
        .catch(error => {          
          this.SSHLoader = false
          this.$awn.alert(Error.showError(error));
        })
      },
      disableSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea deshabilitar el sistema (' + item.name + ')?', {
          title: 'Deshabilitar sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la deshabilitación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })    
      },
      enableSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea habilitar el sistema (' + item.name + ')?', {
          title: 'Habilitar sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'success',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la habilitación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },
      installSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea instalar el sistema (' + item.name + ')?', {
          title: 'Instalar Sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'info',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {   
              this.SSHConsole = 'Problemas en la instalación'           
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },   
      updateSystem(reference, force) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 

        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }    

        var msj = 'Desea actualizar la versión del sistema'
        var title = 'Actualizar sistema'
        if(force) {
          msj = 'Desea actualizar la versión y todas las dependencias del sistema'
          title = 'Actualizar versión y dependencias del sistema'
        }
                
        this.$bvModal.msgBoxConfirm('¿' + msj + ' (' + item.name + ')?', {
          title: title,
          size: 'md',
          buttonSize: 'md',
          okVariant: 'warning',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'warning',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {   
              this.SSHConsole = 'Problemas en la actualización'           
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },          
      uninstallSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea desinstalar el sistema (' + item.name + ')?', {
          title: 'Desinstalar sistema',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la desinstalación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })       
      },    
      disableWebSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 

        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea deshabilitar el sitio web (' + item.name + ')?', {
          title: 'Deshabilitar sitio web',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la deshabilitación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })    
      },
      enableWebSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }    

        this.$bvModal.msgBoxConfirm('¿Desea habilitar el sitio web (' + item.name + ')?', {
          title: 'Habilitar sitio web',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'success',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'success',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la habilitación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },          
      installWebSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea instalar el sitio web para el sistema (' + item.name + ')?', {
          title: 'Instalar sitio web',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'info',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'info',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {   
              this.SSHConsole = 'Problemas en la instalación'           
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },   
      updateWebSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea actualizar la versión del sitio web para el sistema (' + item.name + ')?', {
          title: 'Actualizar versión sitio web',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'warning',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'warning',
          headerTextVariant: 'dark',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {   
              this.SSHConsole = 'Problemas en la actualización'           
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })        
      },          
      uninstallWebSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea desinstalar el sitio web para el sistema (' + item.name + ')?', {
          title: 'Desinstalar sitio web',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en la desinstalación'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })       
      },        
      installProductsSystem(reference, product) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }                  
                
        this.$bvModal.msgBoxConfirm('¿Desea instalar el producto ['+ product +'] en el sistema (' + item.name + ')?', {
          title: 'Instalar Producto',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {   
              this.SSHConsole = 'Problemas en la instalación'           
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },

      backupsConfigurateSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 

        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }            
                
        this.$bvModal.msgBoxConfirm('¿Desea configurar el backups para el sistema (' + item.name + ')?', {
          title: 'Configurar Backups',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en configurar el backups'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        }) 
      },
      backupsDeleteSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        }

        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }    
                
        this.$bvModal.msgBoxConfirm('¿Desea quitar el backups para el sistema (' + item.name + ')?', {
          title: 'Quitar Backups',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en quitar el backups'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },

      cleanLogErrorSystem(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }        
                
        this.$bvModal.msgBoxConfirm('¿Desea limpiar el log de errores para el sistema (' + item.name + ')?', {
          title: 'Limpiar Log de Errores',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en limpiar el log de errores'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })  
      },
      cleanLogErrorSite(reference) {
        var item = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } 
        
        if(!this.selectedSystem) {
          this.$awn.alert("No se encontró el sistema");
          return false
        } else {
          item = this.selectedSystem
        }        
                
        this.$bvModal.msgBoxConfirm('¿Desea limpiar el log de errores para el sitio (' + item.name + ')?', {
          title: 'Limpiar Log de Errores',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'dark',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            this.SSHLoader = true
            var result = serviceAPI.commandActionSistema({
              id: this.selectedServer.id, 
              command: reference,
              reference: this.selectedSystem.name,
              domain: this.selectedServer.subdomain,
              system_id: this.selectedSystem.id,
            });

            result.then((response) => {   
              var data = response.data

              this.SSHConsole = data
              this.filter()
              this.SSHLoader = false
            })
            .catch(error => {              
              this.SSHConsole = 'Problemas en limpiar el log de errores'
              this.SSHLoader = false
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })          
      },

      domainSystem() {
        this.crud.formSystem.id = this.selectedSystem.id
        this.crud.formSystem.domain = this.selectedSystem.domain

        this.modal.formSystem.title = "Apuntar Dominio a Sistema: [" + this.selectedSystem.name + "]"        
        this.modal.formSystem.active = true
      },
      domainSite() {
        this.crud.formSite.id = this.selectedSystem.id
        this.crud.formSite.domain = this.selectedSystem.domain_site

        this.modal.formSite.title = "Apuntar Dominio a Sitio Web: [" + this.selectedSystem.name + "]"        
        this.modal.formSite.active = true        
      },      
      saveDomain(type) {
        this.modal.formSystem.active = false
        this.modal.formSite.active = false

        // comandos
        var item = null
        var reference = ''
        var newDomain = null

        if(!this.selectedServer) {
          this.$awn.alert("No se encontró el servidor");
          return false
        } else {
          item = this.selectedServer
        }

        if(type=='system') {
          reference = 'system-domain'
          newDomain = this.crud.formSystem
        } 
        if(type=='site') {
          reference = 'system-web-domain'
          newDomain = this.crud.formSite
        }         

        this.SSHLoader = true
        var result = serviceAPI.commandActionSistema({
          id: this.selectedServer.id, 
          command: reference,
          reference: this.selectedSystem.name,
          domain: this.selectedServer.subdomain,
          system_id: this.selectedSystem.id,
          newDomain: newDomain,
        });

        result.then((response) => {                    
          var data = response.data

          this.SSHConsole = data
          this.filter()
          this.SSHLoader = false          
        })
        .catch(error => {          
          this.SSHLoader = false
          this.$awn.alert(Error.showError(error));
        })
      },   

      // FILTROS SAVE
      saveFilterStorage() {
        Storage.setValue('filter_view_instances_system', JSON.stringify(this.filters))
      },
      restoreFilterStorage() {
        if(Storage.getValue('filter_view_instances_system')) {
          this.filters = JSON.parse(Storage.getValue('filter_view_instances_system'))
        }         
      },       
    }    
  }
</script>
<style>
  .ssh-console-system {
    background-color: black;
    background-image: radial-gradient(
      rgba(0, 150, 0, 0.75), black 120%
    );
    height: 175px;
    color: white;
    font: 13px Inconsolata, monospace;    
    text-shadow: 0 0 5px #C8C8C8;
    padding: 10px;
    white-space: pre;
    overflow: auto;
  }
  .ssh-console-system::after {
    content: "";
    /*position: absolute;*/
    top: 0;
    left: 0;
    width: 100vw;
    height: 175px;
    background: repeating-linear-gradient(
      0deg,
      rgba(black, 0.15),
      rgba(black, 0.15) 1px,
      transparent 1px,
      transparent 2px
    );
    padding: 10px;
  }  
  .ssh-console-system::selection {
    background: #0080FF;
    text-shadow: none;
  }  
  .ssh-console-footer {
    z-index: 9999;
  }
  .instance-system-divide {
    margin-top: 1px;
    margin-bottom: 10px;    
  }  
  #instances_system_table .table-dark {
    background-color: #676363 !important;
  }
  #instances_system_table .table-dark, .table-dark > th, .table-dark > td  {
      background-color: #676363 !important;
  }    

  .intances-audit-pre-json {
    overflow: auto;    
    height: 300px;
    width: 100%;
    min-width: unset;
  }
  .json-tree-value {
      overflow: unset;
      text-overflow: unset;
      white-space: nowrap;
  }  
  .json-tree-collapsed {
      overflow: unset;
      text-overflow: unset;
      white-space: nowrap;    
  }  
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>